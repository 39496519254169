import './App.css';
import React, { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Pocketbase from "pocketbase";
import KerdoIvSegitseg from './KerdoivSegitseg';

const KerdoIvCel = React.lazy(() => import('./KerdoIvCel'));
const KerdoIvMitGyujtunk = React.lazy(() => import('./KerdoIvMitGyujtunk'));
const KerdoIvMennyiIdeig = React.lazy(() => import('./KerdoIvMennyiIdeig'));
const KerdoIvProgramrol = React.lazy(() => import('./KerdoIvProgramrol'));
const GraphComponent = React.lazy(() => import('./graphComponent'));
const WelcomeScreen = React.lazy(() => import('./welcomeScreen'));
const Selection = React.lazy(() => import('./formSelection'));
const FormEnd = React.lazy(() => import('./formEnd'));
const FormGreeting = React.lazy(() => import('./formGreeting'));
const AdminLogin = React.lazy(() => import('./adminApp'));
const FormLoader = React.lazy(() => import('./loadForm'));
const FormQuestion = React.lazy(() => import('./formQuestion'));

const router = createBrowserRouter([
  { 
    path: '/',
    element: <MainApp />
  },
  {
    path: '/graph',
    element: <Suspense fallback={<p>Töltés...</p>}><GraphComponent /></Suspense>
  },
  {
    path: 'researcher',
    element: <Suspense fallback={<p>Töltés...</p>}><AdminLogin /></Suspense>
  },
  {
    path: '/kerdoivCel',
    element: <Suspense fallback={<p>Töltés...</p>}><KerdoIvCel /></Suspense>
  },
  {
    path: '/kerdoivMitGyujtunk',
    element: <Suspense fallback={<p>Töltés...</p>}><KerdoIvMitGyujtunk /></Suspense>
  },
  {
    path: '/kerdoivMennyiIdeig',
    element: <Suspense fallback={<p>Töltés...</p>}><KerdoIvMennyiIdeig /></Suspense>
  },
  {
    path: '/kerdoivProgramrol',
    element: <Suspense fallback={<p>Töltés...</p>}><KerdoIvProgramrol /></Suspense>
  },
  {
    path: '/segitseg',
    element: <Suspense fallback={<p>Töltés...</p>}><KerdoIvSegitseg /></Suspense>
  }
])

//<Link style={{ color: "#c2c7d0", textDecoration: "none", position: "fixed", bottom: "20px", right: "20px"}} to="/researcher">Kutatói hozzáférés</Link>

function MainApp() {

  const [ globalState, setGlobalState ] = React.useState("welcome");
  const [ currentQuestion, setCurrentQuestion ] = React.useState(0);
  const [ userData, setUserData ] = React.useState([]);
  const [type, setType] = React.useState("unset");
  const [ inProgressCode, setInProgressCode ] = React.useState("");
  const [ inProgressId, setInProgressId ] = React.useState("unset");
  const [ savedData, setSavedData ] = React.useState(null);

  switch (globalState) {
    case "welcome":
      return (
      <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"></link>
      <Suspense fallback={<p>Töltés...</p>}>
      <WelcomeScreen  setGlobalState={setGlobalState} setType={setType} type={type} />
      </Suspense>
      </>
    );
    case "selection":
      return (
    <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"></link>
    <Suspense fallback={<p>Töltés...</p>}>
    <Selection  setGlobalState={setGlobalState} setType={setType} type={type} />
    </Suspense>
    </>
  );
  case "load":
    return (
    <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"></link>
    <Suspense fallback={<p>Töltés...</p>}>
    <FormLoader setGlobalState={setGlobalState} setInProgressId={setInProgressId} setSavedData={setSavedData} setType={setType} />
    </Suspense>
    </>
    );
  case "start":
  return (
    <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"></link>
    <Suspense fallback={<p>Töltés...</p>}>
    <FormGreeting setGlobalState={setGlobalState} type={type} setInProgressId={setInProgressId} inProgressId={inProgressId} inProgressCode={inProgressCode} setInProgressCode={setInProgressCode} />
    </Suspense>
    </>
  );
    case "form":
    return (
      <>
        <Suspense fallback={<p>Töltés...</p>}>
        <FormQuestion setCurrentQuestion={setCurrentQuestion} inProgressCode={inProgressCode} currentQuestion={currentQuestion} setGlobalState={setGlobalState} userData={userData} setUserData={setUserData} globalState={globalState} type={type} inProgressId={inProgressId} savedData={savedData} />
        </Suspense>
      </>
    );
    case "end":
     return (
      <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"></link>
      <div className="end-div">
        <Suspense fallback={<p>Töltés...</p>}>
      <FormEnd userData={userData} />
      </Suspense>
      </div>
      </>
    );  
    default:
    return (
      <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"></link>
      <p>Hiba, kattinson <a onClick={setGlobalState("welcome")} href='/'>ide</a> a főoldalra való visszatéréshez.</p>
      </>
    );
  }
}

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
